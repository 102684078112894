import { render, staticRenderFns } from "./commonTop.vue?vue&type=template&id=1ddff376&scoped=true"
import script from "./commonTop.vue?vue&type=script&lang=js"
export * from "./commonTop.vue?vue&type=script&lang=js"
import style0 from "./commonTop.vue?vue&type=style&index=0&id=1ddff376&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ddff376",
  null
  
)

export default component.exports