<style scoped>
.back {
  padding: 3px 10px;
  width: 100%;
  height: 44px;
  /* background: var(--mainTopColor); */
  background: white;
  font-weight: bold;
  /* color: #2c2c2c; */

  line-height: 44px;
  display: flex;
  position: relative;
  text-align: center;
  color: #5a5f69;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
/* 排序下拉加载 */

.sortContainer {
  background: #386ba1;
  color: white;
  padding: 0 20px;
  position: absolute;
  right: 8px;
  top: 50px;
  z-index: 999;
}

.sortContainer .show,
.sortContainer .hide {
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
.sortContainer .show {
  display: block;
  max-height: 9999px;
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  animation: showAnimation 0.2s ease-in-out;
  -moz-animation: showAnimation 0.2s ease-in-out;
  -webkit-animation: showAnimation 0.2s ease-in-out;
  -moz-transition: max-height 0.2s ease-in-out;
  -o-transition: max-height 0.2s ease-in-out;
  -webkit-transition: max-height 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out;
}
.sortContainer .hide {
  max-height: 0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  animation: hideAnimation 0.2s ease-out;
  -moz-animation: hideAnimation 0.2s ease-out;
  -webkit-animation: hideAnimation 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  -webkit-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
}

@keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-moz-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-webkit-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-moz-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-webkit-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
</style>
<template>
  <div class="back">
    <div v-if="showBack">
      <span style="position: absolute; left: 10px; top: 9px" @click="back">
        <svg
          t="1645171851221"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="81479"
          width="30"
          height="30"
        >
          <path
            d="M475 276V141.4c-12.1-56.3-58.2-22-58.2-22L96.6 395.9c-70.4 48.9-4.8 85.7-4.8 85.7l315.4 274.1c63.1 46.5 67.9-24.5 67.9-24.5V606.4C795.3 506 926.3 907.5 926.3 907.5c12.1 22 19.4 0 19.4 0C1069.4 305.4 475 276 475 276z"
            p-id="81480"
            fill="#5a5f69"
          ></path>
        </svg>
      </span>
    </div>
    <div style="flex: 1" @dblclick="dblclickFun">{{ title }}</div>

    <div v-if="isShowSearch">
      <div
        style="
          font-weight: bold;
          height: 44px;
          position: absolute;
          display: flex;
          right: 20px;
        "
        @click="search"
      >
        <div style="color: #515a6e">高级搜索</div>
        <svg
          style="margin: 10px 0 0 1px"
          t="1647508623014"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="2577"
          width="20"
          height="20"
        >
          <path
            d="M824.9344 150.016H174.336c-37.4272 0-56.8832 44.5952-31.4368 71.9872l234.5984 252.8256c9.4208 10.1376 14.6432 23.5008 14.6432 37.376v289.8432c0 13.4656 8.3456 25.5488 20.992 30.2592l165.2224 61.7472c11.4688 4.3008 23.7568-4.1984 23.7568-16.4864V505.9072c0-14.2848 5.5808-28.0064 15.5136-38.2976l237.9776-244.8896c26.4704-27.136 7.2192-72.704-30.6688-72.704z"
            fill="#C9C9F4"
            p-id="2578"
          ></path>
          <path
            d="M282.4192 213.8112s149.5552 152.0128 165.9392 168.3968c32.768 32.768 25.1392 115.6096 25.1392 115.6096L225.28 210.5856l57.1392 3.2256z"
            fill="#F8F8FF"
            p-id="2579"
          ></path>
          <path
            d="M181.9648 153.088l272.2816 276.5824c12.8 13.0048 19.968 30.464 19.968 48.6912v382.208l-84.5824-38.656V474.5728L112.7424 210.0224s-15.36-50.7904 69.2224-56.9344z"
            fill="#DEDEFF"
            p-id="2580"
          ></path>
          <path
            d="M855.1936 223.8464H151.5008S129.1776 153.088 174.592 153.088h633.7024c44.544 0 59.1872 42.6496 46.8992 70.7584z"
            fill="#E6E6FF"
            p-id="2581"
          ></path>
          <path
            d="M809.0112 153.088l-272.2816 276.5824a69.376 69.376 0 0 0-19.968 48.6912v382.208l84.5824 33.8432v-419.84l276.8384-264.5504c0.0512 0 15.4624-50.7904-69.1712-56.9344z"
            fill="#B2B2EA"
            p-id="2582"
          ></path>
          <path
            d="M584.5504 920.7296c-5.0688 0-10.1888-0.9216-15.104-2.7136l-165.2224-61.7472c-22.528-8.3968-37.632-30.208-37.632-54.2208v-289.8432c0-7.424-2.7648-14.4896-7.8336-19.968L124.16 239.4112c-18.7904-20.2752-23.6032-48.5888-12.544-73.9328s35.072-41.0624 62.72-41.0624h650.5472c28.0064 0 52.1728 16.0256 63.0784 41.8304 10.9056 25.8048 5.5296 54.272-13.9776 74.3424l-237.9776 244.8896a29.29664 29.29664 0 0 0-8.2944 20.4288v371.6608c0 14.1312-6.9632 27.392-18.5856 35.4816a42.9568 42.9568 0 0 1-24.576 7.68zM174.336 175.616c-10.1888 0-14.4384 7.2192-15.8208 10.3424s-3.7888 11.1616 3.1744 18.6368L396.288 457.472c13.8752 14.9504 21.504 34.4064 21.504 54.784v289.8432c0 2.7648 1.7408 5.2736 4.352 6.2464l154.4192 57.7024V505.9072c0-21.0944 8.0896-41.0112 22.784-56.1152l237.9776-244.8896c7.2192-7.424 4.864-15.5648 3.5328-18.7392-1.3312-3.1744-5.5808-10.5472-15.872-10.5472H174.336z"
            fill="#434A54"
            p-id="2583"
          ></path>
          <path
            d="M878.2336 592.4352h-150.7328c-14.1312 0-25.6-11.4688-25.6-25.6s11.4688-25.6 25.6-25.6h150.7328c14.1312 0 25.6 11.4688 25.6 25.6s-11.4688 25.6-25.6 25.6zM878.2336 720.8448h-150.7328c-14.1312 0-25.6-11.4688-25.6-25.6s11.4688-25.6 25.6-25.6h150.7328c14.1312 0 25.6 11.4688 25.6 25.6s-11.4688 25.6-25.6 25.6zM878.2336 846.2336h-150.7328c-14.1312 0-25.6-11.4688-25.6-25.6s11.4688-25.6 25.6-25.6h150.7328c14.1312 0 25.6 11.4688 25.6 25.6s-11.4688 25.6-25.6 25.6z"
            fill="#434A54"
            p-id="2584"
          ></path>
        </svg>
        <!-- <svg
          style="margin: 10px 0 0 4px"
          t="1645436106046"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          
          p-id="10355"
          width="20"
          height="20"
        >
          <path
            d="M774.816 865.76c-189.056 144.608-460.64 130.464-633.568-42.464-188.32-188.352-188.32-493.696 0-682.048 188.352-188.32 493.696-188.32 682.048 0 172.928 172.928 187.072 444.48 42.464 633.568l139.392 139.424a64.32 64.32 0 0 1-90.912 90.912l-139.424-139.392zM232.192 732.352c138.112 138.112 362.048 138.112 500.16 0 138.112-138.112 138.112-362.048 0-500.16-138.112-138.112-362.048-138.112-500.16 0-138.112 138.112-138.112 362.048 0 500.16z"
            p-id="10356"
            fill="#5a5f69"
          ></path>
        </svg> -->
        <!-- fill="#5a5f69" -->
      </div>
    </div>
    <div v-if="isFilter">
      <div
        class="dropdown"
        style="
          font-weight: bold;
          height: 44px;
          position: absolute;
          display: flex;
          right: 20px;
        "
        @click="sort"
      >
        <div style="color: #515a6e">排序</div>
        <svg
          style="margin: 10px 0 0 1px"
          t="1657850692458"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="8637"
          width="20"
          height="20"
        >
          <path
            d="M437 885.4c-9.4 0-18.6-3.7-25.5-10.5L226.7 690.1c-14.1-14.1-14.1-36.9 0-50.9 14.1-14.1 36.9-14.1 50.9 0L401 762.5V178c0-19.9 16.1-36 36-36s36 16.1 36 36v671.4c0 14.6-8.8 27.7-22.2 33.3-4.5 1.8-9.2 2.7-13.8 2.7zM586 560c-19.9 0-36-16.1-36-36V178c0-14.6 8.8-27.7 22.2-33.3 13.5-5.6 29-2.5 39.2 7.9l169 169.5c14 14.1 14 36.9-0.1 50.9s-36.9 14-50.9-0.1L622 265.1V524c0 19.9-16.1 36-36 36zM802 761H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36zM802 653H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36zM802 869H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36z"
            p-id="8638"
          ></path>
          <path
            d="M586.9 617m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
            p-id="8639"
          ></path>
          <path
            d="M586.9 725m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
            p-id="8640"
          ></path>
          <path
            d="M585.9 833m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
            p-id="8641"
          ></path>
        </svg>
      </div>

      <div class="sortContainer">
        <div
          style="
            height: 40px;
            box-shadow: 0 1px 0 #409ad5 inset, 0 -1px 0 #20638f inset;
          "
          :class="showFilterClass"
          @click="sortData(item)"
          v-for="(item, key) in sortList"
          :key="key"
        >
          {{ item.name }}
          <svg
            v-if="item.order == 'asc'"
            t="1657868708200"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="13433"
            width="16"
            height="16"
          >
            <path
              d="M454.99 152.17l0.01 0.792v767.895C455 946.894 433.894 968 407.857 968c-26.036 0-47.142-21.106-47.142-47.143l-0.001-440.517-231.48-6.372c-26.119-0.626-38.81-32.014-20.829-50.625l0.554-0.56L404.114 131.67c18.704-18.447 50.247-5.512 50.876 20.5z m495.72 638.78l0.494 0.004c16.285 0.264 29.405 13.548 29.405 29.896 0 16.513-13.387 29.9-29.9 29.9H548l-0.494-0.004c-16.286-0.264-29.406-13.548-29.406-29.896 0-16.513 13.387-29.9 29.9-29.9h402.71z m-82.8-195.4l0.494 0.003c16.285 0.264 29.405 13.548 29.405 29.896 0 16.514-13.387 29.9-29.9 29.9H548l-0.494-0.004c-16.286-0.264-29.406-13.548-29.406-29.896 0-16.513 13.387-29.9 29.9-29.9h319.91z m-82.8-195.5l0.494 0.003c16.285 0.264 29.405 13.548 29.405 29.896 0 16.513-13.387 29.9-29.9 29.9H548l-0.494-0.004c-16.286-0.264-29.406-13.548-29.406-29.896 0-16.513 13.387-29.9 29.9-29.9h237.11z m-87.4-195.5l0.494 0.003c16.285 0.264 29.405 13.548 29.405 29.896 0 16.513-13.387 29.9-29.9 29.9H548l-0.494-0.004c-16.286-0.264-29.406-13.548-29.406-29.896 0-16.513 13.387-29.9 29.9-29.9h149.71z"
              fill="#fff"
              p-id="13434"
            ></path>
          </svg>
          <svg
            v-else
            t="1657855187286"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="9893"
            width="16"
            height="16"
          >
            <path
              fill="#fff"
              d="M279.15323 958.059228l217.110799-363.160177-141.539436 0L354.724593 63.957829l-151.123938 0 0 530.943021L62.057421 594.900849 279.15323 958.059228 279.15323 958.059228zM570.078783 64.464885l386.443791 0 0 108.976114L570.078583 173.440999 570.078783 64.464885 570.078783 64.464885zM570.078783 369.594007 878.364965 369.594007l0-108.974515L570.078783 260.619492 570.078783 369.594007zM570.078783 565.747016l230.128573 0 0-108.976114L570.078783 456.770901 570.078783 565.747016 570.078783 565.747016zM570.078783 761.904621l151.972163 0L722.050945 652.930305l-151.972163 0L570.078783 761.904621zM570.078783 958.059228l73.813355 0 0-108.974315-73.813355 0L570.078783 958.059228z"
              p-id="9894"
            ></path>
          </svg>

          <!-- <svg
            t="1657854692020"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            p-id="9642"
            width="16"
            height="16"
          >
            <path
              fill="#fff"
              d="M742 624.1c-7.1-7-16.2-10.5-25.5-10.5s-18.5 3.5-25.5 10.5l-59 59V245.5c0-19.9-16.1-36-36-36s-36 16.1-36 36v524c0 0.6 0.1 1.3 0.1 1.9 0.3 8.7 3.8 17.3 10.5 24 14 14.1 36.8 14.1 50.9 0L741.9 675c14.1-14.1 14.1-36.9 0.1-50.9z"
              p-id="9643"
            ></path>
          </svg> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonTop",
  display: "返回顶部",
  props: {
    title: {
      type: String,
      default: "",
    },
    needEmit: {
      type: Boolean,
      default: false,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    isShowSearch: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    sortList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      showFilterClass: "hide",
    };
  },
  computed: {},
  components: {},
  methods: {
    back() {
      if (this.needEmit) {
        this.$emit("back");
      } else {
        this.$router.back(-1);
      }
    },
    search() {
      this.$emit("search");
    },
    dblclickFun() {
      this.$emit("dblclickFun");
    },
    sort() {
      // 下拉展开
      if (this.showFilterClass == "show") {
        this.showFilterClass = "hide";
      } else {
        this.showFilterClass = "show";
      }
    },
    sortData(data) {
      this.showFilterClass = "hide";
      this.$emit("sortData", data);
    },
  },

  async created() {},
  beforeDestroy() {},
};
</script>
