<template>
  <div class="main">
    <common-top
      title="部门完整率"
      :isShowSearch="isShowSearch"
      @search="showModal = true"
    ></common-top>

    <Modal v-model="showDetailStatus" fullscreen>
      <p slot="header" style="text-align: center">
        <span>{{ detailInfo["id"] }}</span>
      </p>
      <div class="commonShadow">
        <div
          style="
            padding: 10px 0;
            text-align: center;
            width: 98%;
            margin: 0 auto;
          "
        >
          总完整率
        </div>
        <pie-chart
          v-if="showDetailStatus"
          :id="`deptUserFollow`"
          :chartSytle="`width:90vw; height: 300px`"
          :option="deptUserOption"
        ></pie-chart>
      </div>
      <div class="commonShadow">
        <div
          style="
            padding: 10px 0;
            text-align: center;
            width: 98%;
            margin: 0 auto;
          "
        >
          录入数据
        </div>
        <pie-chart
          v-if="showDetailStatus"
          :id="`deptUserFollowRate`"
          :chartSytle="`width:90vw; height: 300px`"
          :option="dataNumOption"
        ></pie-chart>
      </div>

      <div slot="footer">
        <Button size="large" long @click="showDetailStatus = false"
          >确定</Button
        >
      </div>
    </Modal>

    <dept-choose-com
      :showModal="showModal"
      @chooseDept="chooseDept"
      :isShowDept="true"
      @cancel="showModal = false"
      :chooseDate="false"
    ></dept-choose-com>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-show="showList && isUserPm">
      <list-com
        @chooseListItem="chooseListItem"
        :columns="columns"
        :title="title"
        :showList="dataList"
        :showLoad="showLoad"
      ></list-com>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import { statsPost, getUserPm } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listCom from "../../components/listCom.vue";
import noPassCom from "../../components/noPassCom.vue";

import deptChooseCom from "../../components/deptLevelChooseCom.vue";

export default {
  name: "deptUserData",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,

    deptChooseCom,
    noPassCom,
  },

  data() {
    return {
      isShowSearch: false, // 是否显示搜索框
      isUserPm: true, // 是否有权限
      filterNameArr: [],

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级
      level: 3,

      showDetailStatus: false,
      detailInfo: {}, // 单位详情
      title: "单位",
      columns: ["总人数", "完整率"],
      showList: true,
      deptData: [],
      dataList: [],
      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},
      defaultOption: {
        legend: {
          orient: "vertical",
          x: "right",
          data: ["未录入", "已录入"],
        },
        // color: ["#507559", "#D8D8D8"],
        title: {
          text: "完整率",
          left: "center",
          top: "42%",
          textStyle: {
            // color: "#507559",
            fontSize: 36,
            align: "center",
          },
        },
        graphic: {
          type: "text",
          left: "center",
          top: "40%",
          style: {
            // text: "完整率",
            textAlign: "center",
            fill: "#333",
            fontSize: 20,
            fontWeight: 700,
          },
        },
        series: [
          {
            name: "关注比例",
            position: "center",

            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
              emphasis: {
                label: {
                  show: false,
                  position: "center",
                  textStyle: {
                    fontSize: "30",
                    fontWeight: "bold",
                  },
                },
              },
            },
            data: [],
          },
        ],
      },
      barOption: {
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [],
        },
        grid: {
          x: 20,
          y: 5,
          x2: 30,
          y2: 35,

          containLabel: true,
        },

        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "#f7623b",
                },
              },
              {
                value: 0,
              },
              {
                value: 0,
              },
              {
                value: 0,
              },
              {
                value: 0,
              },
              {
                value: 0,
              },
              {
                value: 0,
              },
              {
                value: 0,
              },
            ],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      },

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },
  methods: {
    chooseDept(emitData) {
      this.level = emitData.level;
      this.deptIdArr = emitData.deptIdArr;
      this.showModal = false;
      this.getDeptStatus();
    },
    async getDeptStatus() {
      try {
        this.showLoad = true;
        this.showTaskList = false;
        let _query = {
          type: "userInfo",
          level: this.level,
          parentDept: this.deptIdArr,
        };

        const ret = await statsPost("/dept/tj_wbl", _query);
        if (ret.errMsg) {
          this.dailogshow = true;
          this.dailogoptions.content = ret.errMsg;
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
        this.showLoad = false;
        let deptData = ret.ret ? ret.ret : [];
        this.filterNameArr = ret.filterNameArr;
        this.dealData(deptData);
      } catch (error) {
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        console.log("----", error);
      }
    },
    // 处理数据
    dealData(data) {
      // 单位: "职教测试"
      // "单位", "总人数", "关注人数", "完整率"
      let dataList = [];

      let fillStatus = false;

      // 增加过滤数据 非领导班子 站段4级管理员
      if (!_.isEmpty(this.filterNameArr)) {
        fillStatus = true;
      }
      for (const iterator of data) {
        if (iterator["单位"]) {
          // let deptName =
          //   iterator["单位"].length > 6
          //     ? `${iterator["单位"].slice(0, 6)}..`
          //     : iterator["单位"];

          // 完备数: 45001
          // 总人数: 6450
          // 系统: item.subSys,
          // 职名: item.jobTitle,
          // 职务: item.post,
          // 政治面貌: item.politicalStatus,
          // 入职时间: item.entryTime,
          // 学历: item.education,
          // 工作地点: item.workingPlace,
          let readyRate = 0;
          if (iterator["总人数"]) {
            readyRate = (iterator["完备数"] / iterator["总人数"] / 7) * 100;
          }

          dataList.push(
            Object.assign(iterator, {
              id: iterator["单位"],
              总人数: iterator["总人数"],
              单位: iterator["单位"],
              完整率: readyRate,
            })
          );
        }
      }
      dataList = _.reverse(_.sortBy(dataList, ["完整率", "总人数"]));
      this.dataList = [];
      const userDataALl = {
        单位: "汇总",
        完备数: 0,
        总人数: 0,
        系统: 0,
        职名: 0,
        职务: 0,
        政治面貌: 0,
        入职时间: 0,
        学历: 0,
        工作地点: 0,
        完整率: 0,
      };
      for (const iterator of dataList) {
        if (fillStatus) {
          if (this.filterNameArr.indexOf(iterator["单位"]) == -1) {
            continue;
          }
        }

        this.dataList.push(
          Object.assign(iterator, {
            完整率: `${iterator["完整率"].toFixed(2)}%`,
          })
        );

        for (const itemName in iterator) {
          if (!userDataALl[itemName]) {
            userDataALl[itemName] = 0;
          }
          userDataALl[itemName] += iterator[itemName];
        }

        // userDataALl["完备数"] += iterator["完备数"];
        // userDataALl["总人数"] += iterator["总人数"];
        // userDataALl["系统"] += iterator["系统"];
        // userDataALl["职名"] += iterator["职名"];
        // userDataALl["职务"] += iterator["职务"];
        // userDataALl["政治面貌"] += iterator["政治面貌"];
        // userDataALl["入职时间"] += iterator["入职时间"];
        // userDataALl["学历"] += iterator["学历"];
        // userDataALl["工作地点"] += iterator["工作地点"];
        // userDataALl["完整率"] += iterator["工作地点"];
      }

      if (this.dataList.length == 0) {
        return;
      }
      // 增加汇总数据
      if (userDataALl["总人数"]) {
        const readyRate =
          (userDataALl["完备数"] / userDataALl["总人数"] / 7) * 100;
        userDataALl["完整率"] = `${readyRate.toFixed(2)}%`;
      }

      userDataALl["单位"] = "汇总";
      userDataALl["id"] = "汇总";
      this.dataList.push(userDataALl);
    },

    chooseListItem(item) {
      // 显示详情
      this.deptUserOption = null;
      this.detailInfo = item;
      this.showDetailStatus = true;
      let deptUserOption = JSON.parse(JSON.stringify(this.defaultOption));
      // 已激活: 2
      // 已禁用: 0
      // 总人数: 2
      // 未激活: 0
      // 退出企业: 0

      const readyRate = this.detailInfo["完整率"].replace(/\%/g, "");

      deptUserOption["series"][0]["data"].push({
        value:
          parseFloat(readyRate) > 100
            ? "0"
            : `${1 - parseFloat(readyRate) / 100}`,
        // name: "未录入",
        itemStyle: {
          color: "#eee",
        },
      });
      deptUserOption["series"][0]["data"].push({
        value:
          parseFloat(readyRate) > 100 ? "1" : `${parseFloat(readyRate) / 100}`,
        // name: "已录入",
      });

      deptUserOption["title"]["text"] =
        this.detailInfo["完整率"].indexOf("100") > -1
          ? "100%"
          : this.detailInfo["完整率"];

      this.deptUserOption = deptUserOption;

      // 总人数: 6450
      // 系统: item.subSys,
      // 职名: item.jobTitle,
      // 职务: item.post,
      // 政治面貌: item.politicalStatus,
      // 入职时间: item.entryTime,
      // 学历: item.education,
      // 工作地点: item.workingPlace,
      const dataNumOption = JSON.parse(JSON.stringify(this.barOption));
      dataNumOption.series[0].data[0].value = this.detailInfo["总人数"];
      dataNumOption.series[0].data[1].value = this.detailInfo["系统"];
      dataNumOption.series[0].data[2].value = this.detailInfo["职名"];
      dataNumOption.series[0].data[3].value = this.detailInfo["职务"];
      dataNumOption.series[0].data[4].value = this.detailInfo["政治面貌"];

      dataNumOption.series[0].data[5].value = this.detailInfo["入职时间"];
      dataNumOption.series[0].data[6].value = this.detailInfo["学历"];
      dataNumOption.series[0].data[7].value = this.detailInfo["工作地点"];

      dataNumOption.yAxis.data = [
        "总人数",
        "系统",
        "职名",
        "职务",
        "政治面貌",
        "入职时间",
        "学历",
        "工作地点",
      ];
      this.dataNumOption = dataNumOption;
    },
    chooseItem(data) {
      this.showTaskList = false;
      this.deptData = data.data.retData;
    },
  },
  async created() {
    const userPm = getUserPm();
    if (userPm) {
      this.level = userPm.datalevel;

      this.deptIdArr = JSON.parse(localStorage.getItem("pmDeptIdArr"));
      if (userPm.pmValue && userPm.statsPmStatus) {
        this.isShowSearch = true;
      }

      this.getDeptStatus();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped></style>
